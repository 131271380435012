import type { ImageProps } from 'next/image';
import NextImage from 'next/image';
import type { FC } from 'react';

import { useOptimizedImageLoader } from '~/hooks/image';

type Props = ImageProps;

const Image: FC<Props> = (props) => {
  const loader = useOptimizedImageLoader();
  return <NextImage loader={loader} {...props} />;
};

export default Image;
