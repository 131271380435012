import NextLink from 'next/link';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { Fragment } from 'react';

import { Box, Flex, Link, Icon, useDisclosure, Text } from '@chakra-ui/react';
import { FiUser, FiShoppingCart, FiSearch, FiMenu, FiX } from 'react-icons/fi';

import Image from '~/components/common/Image';
import { useAuth } from '~/context/auth';
import { useCart } from '~/context/cart';
import { useSlideMenu } from '~/context/slide_menu';

import { Dropdown } from './Dropdown';
import { SearchDrawer } from './SearchDrawer';
import { SlideMenu } from './SlideMenu';

export type LinkItem = {
  id: string;
  title: string;
  path: string;
  items: LinkItem[];
};

type Props = {
  items: LinkItem[];
};

export const Header: FC<Props> = ({ items }) => {
  const search = useDisclosure();
  const slide = useSlideMenu();
  const { authorization } = useAuth();
  const { items: cartItems } = useCart();
  const { push } = useRouter();
  const handleClickUserIcon = () => {
    if (authorization) {
      push('/my');
      return;
    }
    push('/login');
  };
  return (
    <>
      <Box px={[4, null, 55]} py={[4, null, 25]}>
        <Flex justify="flex-between" align="center">
          <Box flex={1} textAlign="left">
            <NextLink href="/" passHref>
              <a>
                <Image
                  src="/assets/logo.png"
                  width={100}
                  height={25.7}
                  layout="fixed"
                  alt="RUFFLOG"
                />
              </a>
            </NextLink>
          </Box>
          <Box flex={3} textAlign="center" display={['none', null, 'block']}>
            {items.map((i) => (
              <Fragment key={i.id}>
                {i.items.length === 0 ? (
                  <NextLink href={i.path} passHref prefetch={false}>
                    <Link px={5} fontSize={14}>
                      {i.title}
                    </Link>
                  </NextLink>
                ) : (
                  <Dropdown item={i} />
                )}
              </Fragment>
            ))}
          </Box>
          <Box flex={1} textAlign="right">
            <Flex gap={6} justify="flex-end" align="center">
              <Icon
                as={FiSearch}
                w={5}
                h={5}
                onClick={search.onOpen}
                cursor="pointer"
              />
              <Icon
                as={FiUser}
                w={5}
                h={5}
                onClick={handleClickUserIcon}
                cursor="pointer"
              />
              <NextLink href="/cart" passHref>
                <Link pos="relative">
                  <Icon as={FiShoppingCart} w={5} h={5} />
                  {cartItems.length > 0 && (
                    <Flex
                      pos="absolute"
                      w={4}
                      h={4}
                      bg="red.600"
                      top={-2}
                      right={-2}
                      justify="center"
                      align="center"
                      borderRadius={8}
                    >
                      <Text
                        color="white"
                        fontSize={10}
                        p={0}
                        m={0}
                        fontWeight="bold"
                      >
                        {cartItems.length}
                      </Text>
                    </Flex>
                  )}
                </Link>
              </NextLink>
              <Icon
                display={['block', null, 'none']}
                as={slide.isOpen ? FiX : FiMenu}
                w={5}
                h={5}
                onClick={slide.isOpen ? slide.onClose : slide.onOpen}
                cursor="pointer"
              />
            </Flex>
          </Box>
        </Flex>
      </Box>
      <SearchDrawer isOpen={search.isOpen} onClose={search.onClose} />
      <SlideMenu items={items} />
    </>
  );
};
