import { groupBy } from '~/utils/array';

import type { LinkItem } from '..';

export type GroupedLinkItem = LinkItem & { depth: number; parent?: LinkItem };

export function groupByDepth(
  items: LinkItem[],
  depth: number,
  parent?: LinkItem,
  base: GroupedLinkItem[] = [],
): Record<string, GroupedLinkItem[]> {
  items.forEach((i) => {
    base.push({
      ...i,
      depth,
      parent: parent || undefined,
    });
    if (i.items) {
      groupByDepth(i.items, depth + 1, i, base);
    }
  });
  return groupBy(base, (i) => i.depth.toString());
}
