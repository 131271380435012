import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';

import type { MenuItem, ShopQuery } from '~/graphql/shopify/generated';
import { pickPath } from '~/utils/url';

import { Footer } from './Footer';
import type { LinkItem } from './Header';
import { Header } from './Header';

export type BasePageProps<Extend = {}> = { layoutProps: ShopQuery } & Extend;

type Props = {
  pageProps: BasePageProps;
  children: ReactNode;
};

export const Layout: FC<Props> = ({
  children,
  pageProps: {
    layoutProps: { header },
  },
}) => {
  const items = useMemo<LinkItem[]>(() => {
    if (!header) {
      return [];
    }
    return makeItemList(header.items as MenuItem[]);
  }, [header]);
  return (
    <>
      {header && <Header items={items} />}
      {children}
      <Footer />
    </>
  );
};

function makeItemList(items: MenuItem[]): LinkItem[] {
  return items.map(({ id, title, url, items }) => ({
    id,
    title,
    path: pickPath(url),
    items: items ? makeItemList(items) : [],
  }));
}
