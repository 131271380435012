import NextLink from 'next/link';
import type { FC } from 'react';

import {
  Box,
  Button,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Icon,
} from '@chakra-ui/react';
import { FiChevronDown } from 'react-icons/fi';

import type { LinkItem } from '..';

type Props = {
  item: LinkItem;
};

export const Dropdown: FC<Props> = ({ item }) => {
  const hasAnyChild = item.items.some(({ items }) => items.length > 0);
  return (
    <Menu closeOnBlur placement="auto">
      <MenuButton
        as={Button}
        rightIcon={<Icon as={FiChevronDown} />}
        h="inherit"
        bg="inherit"
        fontSize={14}
        fontWeight="normal"
        _hover={{ bg: 'inherit' }}
        _active={{ bg: 'inherit' }}
      >
        {item.title}
      </MenuButton>
      <MenuList zIndex={10}>
        {hasAnyChild ? (
          <Flex gap={10} px={20} py={4}>
            {item.items.map(({ id, path, title, items }) => (
              <Box key={id}>
                <Flex direction="column" align="flex-start" gap={2}>
                  <NextLink key={id} href={path} passHref prefetch={false}>
                    <Link>
                      <Text fontSize={15} fontWeight={700}>
                        {title}
                      </Text>
                    </Link>
                  </NextLink>
                  {items.map(({ id, title, path }) => (
                    <NextLink key={id} href={path} passHref prefetch={false}>
                      <Link fontSize={15}>{title}</Link>
                    </NextLink>
                  ))}
                </Flex>
              </Box>
            ))}
          </Flex>
        ) : (
          <>
            {item.items.map(({ id, path, title }) => (
              <MenuItem
                key={id}
                _focus={{ bg: 'inherit' }}
                _hover={{ bg: 'inherit' }}
              >
                <NextLink href={path} passHref prefetch={false}>
                  <Link>{title}</Link>
                </NextLink>
              </MenuItem>
            ))}
          </>
        )}
      </MenuList>
    </Menu>
  );
};
