import type { FC } from 'react';

import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useI18n } from '~/context/i18n';

export const LocaleSelect: FC = () => {
  const { showModal } = useI18n();
  const { t } = useTranslation('common');
  return (
    <Box onClick={showModal} color="white" fontSize={12} cursor="pointer">
      {t('switchLocale')}
    </Box>
  );
};
