export const footerPages = [
  {
    title: 'About Us',
    href: '/about',
  },
  {
    title: 'Contact',
    href: '/contact',
  },
  {
    title: 'よくある質問',
    href: '/pages/help',
  },
  {
    title: '特定商取引法に基づく表示',
    href: '/pages/特定商取引法に基づく表示',
  },
  {
    title: 'プライバシーポリシー',
    href: '/pages/privacy_policy',
  },
  {
    title: '利用規約',
    href: '/pages/terms_of_service',
  },
];
