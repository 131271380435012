import NextLink from 'next/link';
import type { FC } from 'react';

import { Box, Flex, HStack, Icon, Link, Text } from '@chakra-ui/react';
import { FiInstagram } from 'react-icons/fi';

import { LocaleSelect } from './LocaleSelect/indext';
import { PaymentList } from './PaymentList';
import { footerPages } from './vars';

export const Footer: FC = () => (
  <Box bg="black" py={10} px={[4, null, 9]}>
    <Box w={['100%', null, 1090]} m="0 auto">
      <Box>
        <Text color="#fff" fontWeight={700} mb={5} align="center">
          MENU
        </Text>
        <Flex
          direction={['column', null, 'row']}
          flexWrap={['nowrap', null, 'wrap']}
          justify="center"
          gap={4}
        >
          {footerPages.map(({ href, title }) => (
            <NextLink key={href} href={href} passHref prefetch={false}>
              <Link color="#fff" fontSize={15}>
                {title}
              </Link>
            </NextLink>
          ))}
        </Flex>
      </Box>
      <Box my={10}>
        <Flex
          direction={['column', null, 'row']}
          flexWrap={['nowrap', null, 'wrap']}
          justify="center"
          gap={4}
        >
          <NextLink href="https://www.instagram.com/rufflog/" passHref>
            <Link color="#fff" fontSize={20}>
              <Icon as={FiInstagram} />
            </Link>
          </NextLink>
        </Flex>
      </Box>
      <Box mb={10}>
        <PaymentList />
      </Box>
      <HStack mb={10} justify="center">
        <LocaleSelect />
      </HStack>
      <Box>
        <Text color="#fff" fontSize={15} textAlign="center">
          &copy; 2022- RUFFLOG, inc.
        </Text>
      </Box>
    </Box>
  </Box>
);
