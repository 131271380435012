import type { FC } from 'react';

import { Flex } from '@chakra-ui/react';

import { AmazonPay } from './AmazonPay';
import { Amex } from './Amex';
import { ApplePay } from './ApplePay';
import { GooglePay } from './GooglePay';
import { Jcb } from './Jcb';
import { MasterCard } from './MasterCard';
import { Paypal } from './Paypal';
import { ShopPay } from './ShopPay';
import { Visa } from './Visa';

export const PaymentList: FC = () => (
  <Flex gap={4} justify={['start', 'center']} align="center" flexWrap="wrap">
    <AmazonPay />
    <Amex />
    <ApplePay />
    <GooglePay />
    <Jcb />
    <MasterCard />
    <Paypal />
    <ShopPay />
    <Visa />
  </Flex>
);
