import { useRouter } from 'next/router';
import type { ChangeEventHandler, FC, KeyboardEventHandler } from 'react';
import { useCallback } from 'react';
import { useState } from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { FiSearch, FiX } from 'react-icons/fi';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const SearchDrawer: FC<Props> = (props) => {
  const [query, setQuery] = useState<string>('');
  const { push } = useRouter();
  const handleChangeQuery = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setQuery(e.target.value);
    },
    [],
  );
  const handleKeydown = useCallback<KeyboardEventHandler>(
    (e) => {
      if (e.code.toLowerCase() !== 'enter') {
        return;
      }
      push(`/search?query=${query}`);
      props.onClose();
    },
    [query, props, push],
  );
  return (
    <Drawer {...props} placement="top">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerBody py={12}>
          <Flex
            justify="center"
            align="center"
            gap={3}
            m="0 auto"
            w={['100%', null, '50vw']}
          >
            <InputGroup>
              <Input
                placeholder="検索する"
                value={query}
                onChange={handleChangeQuery}
                onKeyDown={handleKeydown}
              />
              <InputRightElement>
                <Icon as={FiSearch} w={5} h={5} />
              </InputRightElement>
            </InputGroup>
            <Icon as={FiX} w={5} h={5} onClick={props.onClose} />
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
