import { useRouter } from 'next/router';
import type { FC, SyntheticEvent } from 'react';
import { useMemo } from 'react';

import { Box, Flex, Icon, Link, Text } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';

import type { LinkItem } from '..';
import { useSlideMenu } from '../../../../../context/slide_menu';
import type { GroupedLinkItem } from './helper';
import { groupByDepth } from './helper';

type ListProps = {
  depth: number;
  items: GroupedLinkItem[];
};

const List: FC<ListProps> = ({ depth, items }) => {
  const { push } = useRouter();
  const { appendVisibleId, dropLastVisibleId, visibleIds, reset } =
    useSlideMenu();
  const linkPl = useMemo(() => (depth === 0 ? 30 : 70), [depth]);
  const validItems = useMemo(
    () => items.filter((i) => !i.parent || visibleIds.includes(i.parent.id)),
    [items, visibleIds],
  );
  return (
    <Box w="100vw">
      {depth !== 0 && validItems[0] && (
        <Box borderBottomWidth={1} onClick={dropLastVisibleId} maxH={55}>
          <Flex align="center">
            <Box px={4} py={4} maxH={55} borderRightWidth={1}>
              <Icon as={FiChevronLeft} w={5} h={5} />
            </Box>
            <Text p={15} flex={1} fontWeight="bold">
              {validItems[0].parent?.title || ''}
            </Text>
          </Flex>
        </Box>
      )}
      {validItems.map((i) => (
        <Box key={i.id} pl={linkPl} pr={4} py={15} borderBottomWidth={1}>
          {i.items.length === 0 ? (
            <Link
              href={i.path}
              onClick={(e: SyntheticEvent<HTMLAnchorElement>) => {
                e.preventDefault();
                reset();
                push(i.path);
              }}
            >
              {i.title}
            </Link>
          ) : (
            <Flex justify="space-between" onClick={() => appendVisibleId(i.id)}>
              <Text>{i.title}</Text>
              <Icon as={FiChevronRight} w={5} h={5} />
            </Flex>
          )}
        </Box>
      ))}
    </Box>
  );
};

type Props = {
  items: LinkItem[];
};

export const SlideMenu: FC<Props> = ({ items }) => {
  const { depth, isOpen, onClose, visibleIds } = useSlideMenu();
  const groups = useMemo(() => groupByDepth(items, 0, undefined, []), [items]);
  const width = useMemo(
    () => `${Object.keys(groups).length * 100}vw`,
    [groups],
  );
  const height = useMemo(() => {
    if (!isOpen) {
      return 0;
    }
    const length =
      groups[depth].filter((i) => {
        if (!i.parent) {
          return true;
        }
        return visibleIds.includes(i.parent.id);
      }).length + (depth > 0 ? 1 : 0);
    return length * 55;
  }, [isOpen, depth, groups, visibleIds]);
  const transform = useMemo(() => `translateX(${depth * -100}vw)`, [depth]);

  return (
    <Box overflowX="hidden">
      <Box
        tabIndex={0}
        onBlur={onClose}
        zIndex={10}
        width={width}
        height={height}
        transform={transform}
        transition="all .45s cubic-bezier(.29,.63,.44,1);"
      >
        <Flex>
          {Object.keys(groups).map((depth) => (
            <List key={depth} items={groups[depth]} depth={Number(depth)} />
          ))}
        </Flex>
      </Box>
    </Box>
  );
};
