import getConfig from 'next/config';
import type { ImageLoaderProps } from 'next/image';
import { useCallback, useMemo } from 'react';

const isCSR = typeof window !== 'undefined';

export const useOptimizedImageLoader = () => {
  const assetPathPrefix = useMemo(
    () =>
      isCSR
        ? process.env.NEXT_PUBLIC_ASSET_PATH_PREFIX
        : getConfig().serverRuntimeConfig.assetPathPrefix,
    [],
  );
  const cloudinaryOptimizationProfileBaseUrl = useMemo(
    () =>
      isCSR
        ? process.env.NEXT_PUBLIC_CLOUDINARY_OPTIMIZATION_PROFILE_BASE_URL
        : getConfig().serverRuntimeConfig.cloudinaryOptimizationProfileBaseUrl,
    [],
  );

  const loader = useCallback(
    ({ src, width, quality }: ImageLoaderProps) => {
      const prefix = src.match(/cdn.shopify.com/) ? 'shopify' : assetPathPrefix;
      return `${cloudinaryOptimizationProfileBaseUrl}/${prefix}${src.replace(
        'https://cdn.shopify.com/s/files/1/0611/0458/6973',
        '',
      )}?tx=w_${width | 500},q_${quality || 70}`;
    },
    [assetPathPrefix, cloudinaryOptimizationProfileBaseUrl],
  );

  console.log({
    isCSR,
    assetPathPrefix,
    cloudinaryOptimizationProfileBaseUrl,
  });

  if (!assetPathPrefix) {
    return undefined;
  }
  return loader;
};
